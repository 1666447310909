<script setup lang="ts">
import { processImage } from 'lib/images/storyblok'
import { parseURL } from 'lib/url'

const props = withDefaults(defineProps<{
  source?: 'youtube' | 'vimeo' | string
  autoplay?: boolean
  mute?: boolean
  loop?: boolean
  controls?: boolean
  videoId?: string
  thumbnail?: string
  videoSize?: [number, number]

  // Old props
  visibility?: string
  desktopIndent?: string
}>(), {
  videoSize: () => [16, 9],
})

const embeddedVideo = ref<HTMLIFrameElement>()
const iframeHidden = ref<boolean>(!!props.thumbnail && !props.autoplay)
const paddingTop = computed(() => `${props.videoSize[1] / props.videoSize[0] * 100}%`)
const thumbnailProcessed = computed(() => props.thumbnail
  ? processImage(parseURL(props.thumbnail), { fallback: true })
  : undefined,
)
const videoUrl = computed(() => {
  const autoplay = props.autoplay ? 1 : 0
  const loop = props.loop ? 1 : 0
  const controls = props.controls ? 1 : 0
  const mute = props.mute || props.autoplay ? 1 : 0

  if (props.source === 'youtube')
    return `https://www.youtube-nocookie.com/embed/${props.videoId}?autoplay=${autoplay}&loop=${loop}${loop ? `&playlist=${props.videoId}` : ''}&controls=${controls}&mute=${mute}&showinfo=0&rel=0&fs=0&disablekb=1?enablejsapi=1`

  if (props.source === 'vimeo')
    return `https://player.vimeo.com/video/${props.videoId}?autoplay=${autoplay}&loop=${loop}&background=${mute}&color=f2b826&title=0&byline=0`

  return ''
})

/* Play */
function play() {
  if (!embeddedVideo.value || !embeddedVideo.value)
    return

  iframeHidden.value = false
}
</script>

<template>
  <div
    class="video-embed"
    :class="[desktopIndent, visibility]"
    :style="thumbnailProcessed ? { backgroundImage: `url('${thumbnailProcessed}')` } : undefined"
    @click="play"
  >
    <div
      class="video-embed__wrapper"
      :style="`--padding-top: ${paddingTop}`"
    >
      <iframe
        ref="embeddedVideo"
        class="video-embed__iframe"
        :class="{ 'video-embed__iframe--hidden': iframeHidden }"
        :src="videoUrl"
        :width="videoSize[0]"
        :height="videoSize[1]"
        frameborder="0"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.video-embed {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  pointer-events: all !important;

  &__wrapper {
    padding: 0;
    padding-top: var(--padding-top);
    position: relative;
  }

  &__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &--hidden {
      display: none;
    }
  }
}
</style>
